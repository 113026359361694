import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { AuthContext } from "./AuthContext";
export default function BillingKeyMenu() {
  const navigate = useNavigate();

  const context = useContext(AuthContext);

  const { serverAddress } = context;

  const [licenseKey, setLicenseKey] = useState("");

  const backToMenu = () => {
    navigate("/menu");
  };

  const upgrade = async () => {
    const payload = { license_key: licenseKey };
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("fastEmailToken"),
      },
      body: JSON.stringify(payload),
    };
    const response = await fetch(serverAddress + "/upgrade", requestOptions);

    if (response.status == 401) {
      Swal.fire({
        title: "Sorry!",
        text: "License key already in use.",
        icon: "error",
        confirmButtonText: "Ok, Thanks!",
      });
      return;
    }
    if (response.status == 202) {
      Swal.fire({
        title: "Awesome!",
        text: "The license key is valid. Please close the billing page then wait a few seconds for your account to upgrade.",
        icon: "success",
        confirmButtonText: "Ok, Thanks!",
      });
      return;
    }
    Swal.fire({
      title: "Sorry!",
      text: "The license key is not valid.",
      icon: "error",
      confirmButtonText: "Ok, Thanks!",
    });
  };

  return (
    <div>
      <div
        id="FastEmail__popup-settings"
        className="FastEmail__popup-background"
      >
        <div className="container">
          <form className="FastEmail__popup col-lg-7 mx-auto" action="#!">
            <div className="FastEmail__popup__header">
              <h1 className="FastEmail__popup__title m-0 flex-grow-1">
                Billing
              </h1>
              <button
                id="FastEmail__popup-settings-btn"
                className="Close-btn"
                onClick={backToMenu}
              >
                <svg
                  className="bi bi-x"
                  width="24px"
                  height="24px"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.854 4.146a.5.5 0 010 .708l-7 7a.5.5 0 01-.708-.708l7-7a.5.5 0 01.708 0z"
                    clipRule="evenodd"
                  ></path>
                  <path
                    fillRule="evenodd"
                    d="M4.146 4.146a.5.5 0 000 .708l7 7a.5.5 0 00.708-.708l-7-7a.5.5 0 00-.708 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            <hr className="mt-3 mb-0" style={{ color: "#88909b" }} />
            <p className="FastEmail__popup__p mt-3">Upgrade your account</p>
            <p className="FastEmail__popup__p-description">
              Please check your inbox after you purchased a plan that suits you!
            </p>
            <input
              className="FastEmail__popup__input"
              type="text"
              placeholder="Enter Gumroad license key"
              value={licenseKey}
              onChange={(e) => setLicenseKey(e.currentTarget.value)}
            />

            <a href="#" className="btn btn-secondary mt-4" onClick={upgrade}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="svgMargin bi bi-save"
                style={{ transform: "rotate(180deg)" }}
                viewBox="0 0 16 16"
              >
                <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z" />
              </svg>
              Upgrade
            </a>

            <p
              className="FastEmail__popup__p-description"
              style={{
                textWeight: "600",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "30px",
              }}
            >
              Or
            </p>
            <a
              href="#"
              className="btn btn-secondary mt-4"
              onClick={() =>
                window.open("https://swiftreply.gumroad.com/l/swiftreply")
              }
            >
            <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="svgMargin bi bi-box-seam"
            viewBox="0 0 16 16">
            <path d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5l2.404.961L10.404 2l-2.218-.887zm3.564 1.426L5.596 5 8 5.961 14.154 3.5l-2.404-.961zm3.25 1.7-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z"/>
            </svg>
              View Plans
            </a>
          </form>
        </div>
      </div>
    </div>
  );
}
