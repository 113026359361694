import React, { useEffect, useContext, useState, useRef } from "react";
import { AuthContext } from "../Contexts/AuthContext";
import { useNavigate } from "react-router-dom";

import Swal from "sweetalert2";
import GridIcon from "./Icons/GridIcon";
import ListIcon from "./Icons/ListIcon";
import { Button, ButtonGroup, Form } from "react-bootstrap";
import { useMemo } from "react";
import Preview from "./Preview";

const htmlparser = require("htmlparser2");

const TemplateMenu = (props) => {
  const navigate = useNavigate();
  const context = useContext(AuthContext);
  const {
    templates,
    tags,
    serverAddress,
    token,
    getAllTemplates,
    teams,
    selectedTeam,
    isActive,
    currentTeams,
    currentTemplates,
    maxTeams,
    maxTemplates,
    templatesLoaded,
  } = context;

  const [tagSearch, setTagSearch] = useState("");
  const [templateSearch, setTemplateSearch] = useState("");
  // list || grid
  const [listView, setListView] = useState(
    localStorage.getItem("listView") || "grid"
  );
  // alpha || date
  const [sortBy, setSortBy] = useState(localStorage.getItem("sortBy"));

  const templateSection = useRef();
  const tagsSection = useRef();

  const [tagFilter, setTagFilter] = useState("");

  useEffect(() => {
    if (context.isLoggedIn === false) {
      navigate("/login");
    }

    return () => {};
  }, []);

  useEffect(() => {
    console.log(isActive);
  }, [isActive]);

  const previewTemplate = (template) => {
    setPreviewVisible(true);
    setPreviewTitle(template.name);
    setPreviewContent(template.content);
  };
  const filterTemplateContent = (template) => {
    var result = [];
    var parser = new htmlparser.Parser(
      {
        ontext: function (text) {
          result.push(text);
        },
      },
      { decodeEntities: true }
    );

    parser.write(template.content);
    parser.end();

    result.join("");
    return result;
  };

  const getSelectedTeam = () => {
    for (let team of teams) {
      if (team.id == selectedTeam) {
        return team;
      }
    }
    return null;
  };

  const editTemplate = (selectedTemplate) => {
    let selTeam = getSelectedTeam();

    if (
      selTeam.tempEditable ||
      (selTeam.edit_own_templates_permission && selectedTemplate.isMine) ||
      selTeam.name == "Personal Workspace"
    ) {
      navigate("/editTemplate/" + selectedTemplate.id);
    }
  };

  const toggleMobileTagMenu = (condition) => {
    //  var templateSection = document.getElementById("TemplatesSection");
    //  var tagsSection = document.getElementById("TagsSection");

    if (condition === 0) {
      templateSection.current.style.display = "none";
      tagsSection.current.style.display = "block";
    } else {
      templateSection.current.style.display = "block";
      tagsSection.current.style.display = "none";
    }
  };

  const filteredTemplates = useMemo(() => {
    // let newTemplates = [...templates]; //don't keep the same object, make a copy
    return templates
      .filter(
        (template) =>
          template &&
          template.teamId === selectedTeam &&
          (template.name.toUpperCase().indexOf(templateSearch.toUpperCase()) >
            -1 ||
            template.content
              .toUpperCase()
              .indexOf(templateSearch.toUpperCase()) > -1) &&
          (!template.tags ||
            (template.tags &&
              (template.tags.indexOf(tagFilter) > -1 || tagFilter === "")))
      )
      .sort((a, b) => {
        if (sortBy === "alpha") {
          return a.name.localeCompare(b.name);
        }
        // Then it's by date - DESC
        return new Date(b.created_at) - new Date(a.created_at);
      });
  }, [selectedTeam, tagFilter, templateSearch, templates, sortBy]);

  const filteredTags = useMemo(() => {
    // let newTags = [...tags]; //don't keep the same object, make a copy
    return tags.filter(
      (tag) => tag && tag.toUpperCase().indexOf(tagSearch.toUpperCase()) > -1
    );
  }, [tagSearch, tags]);

  const deleteTemplate = (templateId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const requestOptions = {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        };
        fetch(serverAddress + "/deleteTemplate/" + templateId, requestOptions)
          .then((response) => response.json())
          .then((data) => {
            getAllTemplates();
            Swal.fire("Deleted!", "Your template has been deleted.", "success");
          })
          .catch((err) => {});
      }
    });
  };

  const Knowledgebase = () => {
    const win = window.open("https://help.swiftreply.net", "_blank");
    if (win != null) {
      win.focus();
    }
  };

  const handleChangeListView = (type) => {
    setListView(type);
    localStorage.setItem("listView", type);
  };

  const handleSortBy = (e) => {
    const value = e.target.value;
    setSortBy(value);
    localStorage.setItem("sortBy", value);
  };
  const [previewTitle, setPreviewTitle] = useState("");
  const [previewContent, setPreviewContent] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);
  return (
    <div>
      <Preview
        visible={previewVisible}
        setVisible={setPreviewVisible}
        title={previewTitle}
        content={previewContent}
      />
      <div className="FastEmail__Block container-fluid">
        <div className="row align-items-center flex-column-reverse flex-lg-row">
          {/* <!-- Tags Section --> */}
          <div
            ref={tagsSection}
            id="TagsSection"
            className="col-lg-2 FastEmail__tags p-0"
          >
            <div className="close-ui-search-tags py-3">
              <a
                onClick={() => toggleMobileTagMenu(1)}
                id="SearchTags__close-btn"
                className="Close-btn"
                href="#"
              >
                <svg
                  className="bi bi-x"
                  width="1em"
                  height="1em"
                  viewBox="0 0 16 16"
                  fill="white"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.854 4.146a.5.5 0 010 .708l-7 7a.5.5 0 01-.708-.708l7-7a.5.5 0 01.708 0z"
                    clipRule="evenodd"
                  ></path>
                  <path
                    fillRule="evenodd"
                    d="M4.146 4.146a.5.5 0 000 .708l7 7a.5.5 0 00.708-.708l-7-7a.5.5 0 00-.708 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </a>
            </div>
            <div className="ui-container container">
              <div className="ui-search">
                <div className="fr-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1rem"
                    height="1rem"
                    fill="currentColor"
                    className="bi bi-search"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                  </svg>
                </div>
                <input
                  value={tagSearch}
                  onChange={(e) => setTagSearch(e.target.value)}
                  type="text"
                  id="SearchTagInput"
                  onkeyup="SearchTags()"
                  className="ui-search__input"
                  placeholder="Search tags"
                />
              </div>
            </div>
            <div className="FastEmail__scroll container">
              <div id="TagsList" className="FastEmail__tags-list pt-4">
                {/* <!-- Tags Start Here --> */}
                {filteredTags &&
                  filteredTags.map((tag) => (
                    <a
                      onClick={() => {
                        //add a filter for templates which contains this tag
                        if (tagFilter === tag) {
                          setTagFilter("");
                        } else {
                          setTagFilter(tag);
                        }
                      }}
                      key={tag}
                      href="#!"
                      className="FastEmail__tags-link"
                      style={tagFilter === tag ? { color: "#635afe" } : {}}
                    >
                      {"#" + tag}
                    </a>
                  ))}

                {/* <!-- Tags End Here --> */}
              </div>
            </div>
          </div>
          {/* <!-- Templates Section --> */}
          <div
            ref={templateSection}
            id="TemplatesSection"
            className="col-lg-10 FastEmail__templates p-0"
          >
            <div className="ui-container">
              <div className="ui-search">
                <div className="fr-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1rem"
                    height="1rem"
                    fill="currentColor"
                    className="bi bi-search"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                  </svg>
                </div>
                <input
                  value={templateSearch}
                  onChange={(e) => setTemplateSearch(e.target.value)}
                  type="text"
                  id="SearchTemplateInput"
                  className="ui-search__input"
                  placeholder="Search templates by name or content"
                />
              </div>
            </div>
            {(currentTeams > maxTeams || currentTemplates > maxTemplates) && (
              <div className="row align-items-center d-flex warningDiv">
                <span className="warningMain">
                  {`Limits exceeded! Please upgrade, or delete some templates or workspaces before you can use SwiftReply.`}
                </span>
              </div>
            )}

            {currentTeams <= maxTeams &&
              currentTemplates <= maxTemplates &&
              isActive == false && (
                <div className="row align-items-center d-flex warningDiv">
                  <span className="warningMain">
                    {`Limits exceeded! Please upgrade, or delete some templates or workspaces before you can use SwiftReply.`}
                  </span>
                </div>
              )}

            <div className="FastEmail__scroll container px-lg-4 px-md-0">
              <div
                onClick={() => toggleMobileTagMenu(0)}
                id="SearchTags__btn"
                className="ui-search-tags_btn mt-4"
              >
                <a href="#" className="btn btn-secondary">
                  <svg
                    className="svgMargin bi bi-funnel"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2h-11z" />
                  </svg>
                  Filter by Tags
                </a>
              </div>

              <div>
                {templates.length > 0 && (
                  <div className="FastEmail-templates__count">
                    <p className="m-0">
                      {templatesLoaded &&
                        `Total ${filteredTemplates.length} canned responses`}
                    </p>
                    <div className="FastEmail-templates__listview">
                      <ButtonGroup>
                        <Button
                          size="sm"
                          variant={
                            listView === "grid" ? "listview" : "primary--empty"
                          }
                          onClick={() => handleChangeListView("grid")}
                        >
                          <GridIcon />
                        </Button>
                        <Button
                          size="sm"
                          variant={
                            listView === "list" ? "listview" : "primary--empty"
                          }
                          onClick={() => handleChangeListView("list")}
                        >
                          <ListIcon />
                        </Button>
                      </ButtonGroup>
                      <Form.Select
                        size="sm"
                        value={sortBy}
                        onChange={handleSortBy}
                      >
                        <option>Sort By</option>
                        <option value="alpha">Alphabetically</option>
                        <option value="date">Created Date</option>
                      </Form.Select>
                    </div>
                  </div>
                )}
              </div>

              <div>
                {templatesLoaded && templates.length < 1 && (
                  <div className="FastEmail__videoJS col-lg-10 mx-auto mt-5">
                    <p class="FastEmail__tip-title mb-5">
                      Hello 👋, Get started by watching this quick video.
                    </p>
                    <iframe
                      width="100%"
                      height="450"
                      style={{ maxWidth: 710 }}
                      src="https://www.youtube.com/embed/brisT29xUyE?rel=0&showinfo=0&modestbranding=1&controls=1"
                      title="GetStarted - SwiftReply"
                    ></iframe>
                    <p class="FastEmail__tip-title mt-5">
                      Watch more tutorials & hacks here 👇
                    </p>
                    <a
                      href="#"
                      className="btn btn-secondary mt-4"
                      onClick={Knowledgebase}
                    >
                      Visit Knowledge Base
                    </a>
                  </div>
                )}
              </div>

              {/* <!-- Templates Start Here --> */}
              <div id="TemplatesList" className="row px-lg-2 py-4">
                {filteredTemplates &&
                  filteredTemplates.map((template, index) => (
                    <div
                      key={template.id}
                      id="FastEmail-card"
                      className={`
                      col-xl-${listView === "list" ? 12 : 4}
                      col-lg-${listView === "list" ? 12 : 6}
                      pb-4`}
                    >
                      <div className="FastEmail-card">
                        <div className="FastEmail-card__row-top">
                          <span className="FastEmail-card__shortcut">
                            ⚡️ {template.shortcut}
                          </span>
                          <div className="d-flex align-items-center">
                            <button
                              className="FastEmail-card__btn"
                              onClick={() => editTemplate(template)}
                            >
                              {getSelectedTeam().tempEditable ||
                              (getSelectedTeam()
                                .edit_own_templates_permission &&
                                template.isMine) ||
                              getSelectedTeam().name == "Personal Workspace" ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="white"
                                  class="svgMargin bi bi-pencil-square"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                  <path
                                    fill-rule="evenodd"
                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                  />
                                </svg>
                              ) : (
                                ""
                              )}
                            </button>
                            <button
                              className="FastEmail-card__btn"
                              onClick={() => previewTemplate(template)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="1rem"
                                height="1rem"
                                fill="#fff"
                                viewBox="0 0 576 512"
                              >
                                <path d="M279.6 160.4C282.4 160.1 285.2 160 288 160C341 160 384 202.1 384 256C384 309 341 352 288 352C234.1 352 192 309 192 256C192 253.2 192.1 250.4 192.4 247.6C201.7 252.1 212.5 256 224 256C259.3 256 288 227.3 288 192C288 180.5 284.1 169.7 279.6 160.4zM480.6 112.6C527.4 156 558.7 207.1 573.5 243.7C576.8 251.6 576.8 260.4 573.5 268.3C558.7 304 527.4 355.1 480.6 399.4C433.5 443.2 368.8 480 288 480C207.2 480 142.5 443.2 95.42 399.4C48.62 355.1 17.34 304 2.461 268.3C-.8205 260.4-.8205 251.6 2.461 243.7C17.34 207.1 48.62 156 95.42 112.6C142.5 68.84 207.2 32 288 32C368.8 32 433.5 68.84 480.6 112.6V112.6zM288 112C208.5 112 144 176.5 144 256C144 335.5 208.5 400 288 400C367.5 400 432 335.5 432 256C432 176.5 367.5 112 288 112z" />
                              </svg>
                            </button>
                            {getSelectedTeam().tempEditable ||
                            (getSelectedTeam().edit_own_templates_permission &&
                              template.isMine) ||
                            getSelectedTeam().name == "Personal Workspace" ? (
                              <button
                                className="FastEmail-card__btn"
                                onClick={() => deleteTemplate(template.id)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="1rem"
                                  height="1rem"
                                  fill="#ff6471"
                                  className="bi bi-trash"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                  <path
                                    fillRule="evenodd"
                                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                  />
                                </svg>
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="FastEmail-card__row-top mt-4">
                          <span className="FastEmail-card__name flex-grow-1 d-flex">
                            {template.name}
                          </span>
                        </div>
                        <p className="FastEmail-card__paragraph">
                          {filterTemplateContent(template)}
                        </p>
                        <div className="FastEmail-card__row-bottom">
                          {Array.isArray(template.tags) &&
                            template.tags.filter(Boolean).map((tag, i) => {
                              return (
                                <span key={i} className="FastEmail-card__tag">
                                  {"#" + tag}
                                </span>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              {/* <!-- Templates End Here --> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplateMenu;
