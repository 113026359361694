import Header from "./header";
import TemplateMenu from "./templateMenu";
import TemplateForm from "./TemplateForm";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import LoginForm from "./loginForm";
import RegisterForm from "./registerForm";
import GeneralSettings from "./GeneralSettings";
import EditTemplate from "./EditTemplate";
import TeamForm from "./TeamForm";
import EditTeam from "./EditTeam";
import ResetPassword from "./ResetPassword";
import PasswordRetype from "./PasswordRetype";
import BillingMenu from "../Contexts/BillingMenu";
import BillingKeyMenu from "../Contexts/BillingKeyMenu";
import RenameTeam from "./RenameTeam";
import InvitationAcceptance from "./InvitationAcceptance";
import AppsumoRegisterForm from "./AppsumoRegisterForm";

function MainRouter() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/createTemplate" element={<TemplateForm />} />
          <Route path="/createTeam" element={<TeamForm />} />

          <Route path="/login" element={<LoginForm />} />
          <Route path="/register" element={<RegisterForm />} />
          <Route path="/appsumo/register" element={<AppsumoRegisterForm />} />
          <Route path="/editTeam/:id" element={<EditTeam />} />
          <Route path="/renameTeam/:id" element={<RenameTeam />} />
          <Route
            path="/menu"
            element={
              <>
                <Header></Header>
                <TemplateMenu></TemplateMenu>
              </>
            }
          />
          <Route path="/billingMenu" element={<BillingKeyMenu />} />
          <Route path="/billingOptions" element={<BillingMenu />} />
          <Route path="/resetPasswordRequest" element={<ResetPassword />} />
          <Route path="/resetPassword/:token" element={<PasswordRetype />} />
          <Route path="/invitation/:token" element={<InvitationAcceptance />} />
          <Route path="/generalSettings" element={<GeneralSettings />} />
          <Route path="/editTemplate/:id" element={<EditTemplate />} />

          <Route path="/*" element={<Navigate to="/menu" />} />
        </Routes>
      </Router>
    </>
  );
}

export default MainRouter;
