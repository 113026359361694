import React, { useState, useContext } from "react";
import Swal from "sweetalert2";
import { AuthContext } from "../Contexts/AuthContext";
import { useParams, useNavigate } from "react-router-dom";

export default function PasswordRetype() {
  const [password, setPassword] = useState("");
  const context = useContext(AuthContext);
  const { token } = useParams();
  const navigate = useNavigate();

  const goToLogin = () => {
    navigate("/login");
  };

  const { serverAddress } = context;
  const resetPassword = (e) => {
    e.preventDefault();
    let requestData = { token: token, password: password };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    };
    fetch(serverAddress + "/resetPasswordAction", requestOptions)
      .then((response) => {
        if (response.status != 200) {
          throw "Error";
        }
        Swal.fire({
          title: "Password updated successfully!",
          text: "Now you can continue your great experience with SwiftReply.",
          icon: "success",
          confirmButtonText: "Ok, Thanks!",
        }).then(() => {
          navigate("/login");
        });
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: "Sorry!",
          text: "Something went wrong.",
          icon: "error",
          confirmButtonText: "Try Again!",
        }).then(() => {
          navigate("/login");
        });
      });
  };
  return (
    <div className="FastEmail_Background">
      <div className="space-between-blocks">
        <div className="container col-xxl-10 px-xxl-5">
          {/* <!-- HEADER --> */}
          <div className="FastEmail__logo-container">
            <a href="#" onClick={goToLogin}>
              <img className="FastEmail__logo" src="/images/SwiftReply.svg" alt="SwiftReply logo" />
            </a>
          </div>

          {/* <!-- Password reset FORM --> */}
          <div id="Login" className="FastEmail__Login row px-2 justify-content-center">
            <div className="col-lg-5 mt-5 mt-lg-0">
              <form className="FastEmail-auth-form" action="#!" onSubmit={resetPassword}>
                <h1 className="FastEmail-auth-form__title">Write New Password!</h1>
                <input
                  id="password"
                  className="FastEmail-auth-form__input"
                  type="password"
                  placeholder="Enter your new password..."
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <button className="btn btn-form w-100 mt-3">SAVE</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
