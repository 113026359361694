import React, { useContext, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { AuthContext } from "../Contexts/AuthContext";
import Badge from "react-bootstrap/Badge";

export default function EditTeam() {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const context = useContext(AuthContext);

  const { id } = useParams();

  const { token, serverAddress } = context;

  const [teamMembers, setTeamMembers] = useState();

  const requestMembers = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("fastEmailToken"),
      },
    };

    const response = await fetch(
      serverAddress + "/getTeamMembers/" + id,
      requestOptions
    );
    const responseData = await response.json();
    console.log(responseData);
    setTeamMembers(responseData);
  };
  useEffect(async () => {
    ///getTeamMembers/:teamId

    requestMembers();
  }, []);

  const backToMenu = () => {
    navigate("/menu");
  };

  const addMember = async () => {
    if (email == "") {
      Swal.fire({
        title: "Sorry!",
        text: "Member email is mandatory!",
        icon: "error",
        confirmButtonText: "Ok, Thanks!",
      });
      return;
    }

    try {
      let requestData = {
        teamId: id,
        email: email,
      };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(requestData),
      };
      const res = await fetch(serverAddress + "/addToTeam", requestOptions);
      const response = await res.text();
      if (res.status > 202) {
        throw new Error(response);
      }
      setEmail("");
      requestMembers();
      Swal.fire({
        title: "Done!",
        text: response,
        icon: "success",
        confirmButtonText: "Close",
      });
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: "Sorry!",
        text: error.message,
        icon: "error",
        confirmButtonText: "Ok, Thanks!",
      });
    }
  };

  const updatePermissionsOfUser = (member) => {
    let requestData = {
      teamId: id,
      userId: member.user_id,
      add_templates_permission: member.add_templates_permission,
      edit_templates_permission: member.edit_templates_permission,
      edit_own_templates_permission: member.edit_own_templates_permission,
    };

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(requestData),
    };

    fetch(serverAddress + "/updateMemberPermissions", requestOptions)
      .then((responseData) => {
        if (responseData.status != 200) {
          Swal.fire({
            title: "Sorry!",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonText: "Ok, Thanks!",
          }).then(() => backToMenu());
        }
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: "Sorry!",
          text: "Something went wrong!",
          icon: "error",
          confirmButtonText: "Ok, Thanks!",
        }).then(() => backToMenu());
      });
  };

  const kickMember = (member) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let requestData = {
          teamId: id,
          userId: member.user_id,
          invitationId: member.user_id === 0 ? member.id : 0,
        };

        const requestOptions = {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(requestData),
        };

        fetch(serverAddress + "/kickMember", requestOptions)
          .then((responseData) => {
            Swal.fire({
              title: "Member Deleted!",
              text: "The selected member has been removed from the team.",
              icon: "success",
              confirmButtonText: "Ok, Thanks!",
            }).then(() => {
              let members = [];
              for (let i in teamMembers) {
                if (teamMembers[i].id != member.id) {
                  members.push(teamMembers[i]);
                }
              }
              setTeamMembers(members);
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  return (
    <div>
      <div
        id="FastEmail__popup-settings"
        className="FastEmail__popup-background"
      >
        <div className="container">
          <form className="FastEmail__popup col-lg-7 mx-auto" action="#!">
            <div className="FastEmail__popup__header">
              <h1 className="FastEmail__popup__title m-0 flex-grow-1">
                Team Members
              </h1>
              <button
                id="FastEmail__popup-settings-btn"
                className="Close-btn"
                onClick={backToMenu}
              >
                <svg
                  className="bi bi-x"
                  width="24px"
                  height="24px"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.854 4.146a.5.5 0 010 .708l-7 7a.5.5 0 01-.708-.708l7-7a.5.5 0 01.708 0z"
                    clipRule="evenodd"
                  ></path>
                  <path
                    fillRule="evenodd"
                    d="M4.146 4.146a.5.5 0 000 .708l7 7a.5.5 0 00.708-.708l-7-7a.5.5 0 00-.708 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            <hr className="mt-3" style={{ color: "#88909b" }} />
            <p className="FastEmail__popup__p mt-3">Add a new team member</p>
            <p className="FastEmail__popup__p-description">
              Invitation Email will be sent if the member does not have an
              account on SwiftReply.
            </p>
            <input
              className="FastEmail__popup__input"
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value)}
            />

            <a href="#" className="btn btn-secondary mt-4" onClick={addMember}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="svgMargin bi bi-plus-circle"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
              </svg>
              Add Member
            </a>
            <div className="my-4">
              <hr className="my-4" style={{ color: "#88909b" }} />
              <p className="FastEmail__popup__p">Team members list</p>
              <p className="FastEmail__popup__p-description">
                Using templates permissions is available for all members by
                default.
              </p>
            </div>
            {teamMembers &&
              teamMembers.map((member, index) => (
                <div
                  style={{
                    marginBottom: 5,
                    borderRadius: "5px",
                    border: "1px solid #363a40",
                    background: "#282a2e7a",
                    backdropFilter: "blur(40px px )",
                  }}
                  key={member.id}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <p className="FastEmail__popup__list m-0">{member.email}</p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1rem"
                      height="1rem"
                      fill="red"
                      className="bi bi-trash"
                      viewBox="0 0 16 16"
                      style={{
                        margin: "0 1rem",
                        cursor: "pointer",
                      }}
                      onClick={() => kickMember(member)}
                    >
                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                      <path
                        fillRule="evenodd"
                        d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                      />
                    </svg>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                      padding: "0 10px",
                    }}
                  >
                    {member.user_id > 0 ? (
                      <>
                        <div style={{ display: "flex" }}>
                          <label class="switch">
                            <input
                              type="checkbox"
                              checked={member.add_templates_permission}
                              onChange={() => {
                                let aux = [...teamMembers];
                                aux[index].add_templates_permission =
                                  !aux[index].add_templates_permission;

                                //send request to update the permissions
                                updatePermissionsOfUser(aux[index]);
                                setTeamMembers(aux);
                              }}
                            />
                            <span class="slider round"></span>
                          </label>
                          <span
                            style={{
                              color: "var(--text-secondary)",
                              whiteSpace: "nowrap",
                              paddingTop: "3px",
                              cursor: "default",
                            }}
                          >
                            Add Templates
                          </span>
                        </div>
                        <div style={{ display: "flex" }}>
                          <label class="switch">
                            <input
                              type="checkbox"
                              checked={member.edit_templates_permission}
                              onChange={() => {
                                let aux = [...teamMembers];
                                aux[index].edit_templates_permission =
                                  !aux[index].edit_templates_permission;
                                if (aux[index].edit_templates_permission) {
                                  aux[
                                    index
                                  ].edit_own_templates_permission = false;
                                }

                                //send request to update the permissions
                                updatePermissionsOfUser(aux[index]);

                                console.log(member);
                                setTeamMembers(aux);
                              }}
                            />
                            <span class="slider round"></span>
                          </label>
                          <span
                            style={{
                              color: "var(--text-secondary)",
                              whiteSpace: "nowrap",
                              paddingTop: "3px",
                            }}
                          >
                            Edit/Delete All Users Templates
                          </span>
                        </div>
                        <div style={{ display: "flex" }}>
                          <label class="switch">
                            <input
                              type="checkbox"
                              checked={member.edit_own_templates_permission}
                              onChange={() => {
                                let aux = [...teamMembers];
                                aux[index].edit_own_templates_permission =
                                  !aux[index].edit_own_templates_permission;

                                if (aux[index].edit_own_templates_permission) {
                                  aux[index].edit_templates_permission = false;
                                }
                                //send request to update the permissions
                                updatePermissionsOfUser(aux[index]);
                                console.log(member);
                                setTeamMembers(aux);
                              }}
                            />
                            <span class="slider round"></span>
                          </label>
                          <span
                            style={{
                              color: "var(--text-secondary)",
                              whiteSpace: "nowrap",
                              paddingTop: "3px",
                            }}
                          >
                            Edit/Delete Its Own Templates
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        <Badge bg="primary" className="mb-1 mx-2">
                          Pending Invitation
                        </Badge>
                        <span
                          style={{
                            color: "var(--text-secondary)",
                            fontSize: 11,
                          }}
                          className="mb-1"
                        >
                          Since: {new Date(member.created_at).toLocaleString()}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              ))}
          </form>
        </div>
      </div>
    </div>
  );
}
