import React, { useState, useContext } from "react";
import "./loginForm.css";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Contexts/AuthContext";
import { useEffect } from "react";
import { useCallback } from "react";

const AppsumoRegisterForm = () => {
  const {
    serverAddress,
    setToken,
    setLoggedIn,
    getAllTemplates,
    getAllTeams,
    getSubscription,
    getUserSettings,
  } = useContext(AuthContext);
  const params = new URLSearchParams(window.location.search);
  const id = params.get("id");

  const navigate = useNavigate();
  const [info, setInfo] = useState();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const getSumoInfo = useCallback(async () => {
    try {
      if (!id || id.length < 32) {
        return navigate("/");
      }
      const res = await fetch(serverAddress + "/appsumo/" + id, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      });
      const info = await res.json();
      if (res.status !== 200) {
        throw new Error(info);
      }
      setInfo(info);
    } catch (error) {
      setError(error.message || "Something's went wrong...");
    }
  }, [serverAddress, navigate, id]);

  useEffect(() => {
    getSumoInfo();
  }, [getSumoInfo]);

  const register = async (e) => {
    e.preventDefault();

    try {
      if (!!error) setError("");
      setLoading(true);

      const res = await fetch(serverAddress + "/appsumo/confirm/" + id, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username, password }),
      });

      const info = await res.json();

      if (res.status !== 201) {
        throw new Error(info);
      }

      // I don't like this at all
      // But that's the setup they had before
      localStorage.setItem("fastEmailToken", info.accessToken);
      setToken(info.accessToken);
      await getAllTeams();
      await getSubscription();
      await getUserSettings();
      await getAllTemplates();
      setLoggedIn(true);
      const event = new CustomEvent("fastEmailLogin", {
        detail: { token: info.accessToken },
      });
      document.dispatchEvent(event);
      navigate("/menu");
    } catch (error) {
      setError(error.message || "Something's went wrong...");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fullheight">
      <div className="flex_centralize">
        <div className="container col-xxl-10 px-xxl-5 py-4">
          {/* <!-- HEADER --> */}
          <div className="">
            <img
              className="FastEmail__logo px"
              src={"/images/SwiftReply.svg"}
              alt="SwiftReply logo"
            />
            <span className="FastEmail__plus__logo">+</span>
            <img className="FastEmail__logo px" src={"/images/appsumo.svg"} alt="AppSumo logo" />
          </div>
          {/* <!-- SIGN UP FORM --> */}
          <div id="Signup" className="FastEmail__Signup row px-2 justify-content-center">
            <div className="col-lg-5 py-2">
              <form className="FastEmail-auth-form" action="#!" onSubmit={register}>
                <h2 className="">Hey Sumo-lings! 👋</h2>
                <p>
                  You've subscribed successfully to SwiftReply plan{" "}
                  <strong>{info?.plan_name || ""}</strong>
                </p>
                <p>setup your username and password to get started</p>
                <input
                  id="email"
                  className="FastEmail-auth-form__input"
                  type="email"
                  placeholder="Email"
                  value={info?.email || ""}
                  disabled
                />
                <input
                  id="userName"
                  className="FastEmail-auth-form__input"
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />

                <input
                  id="password"
                  className="FastEmail-auth-form__input"
                  type="password"
                  minLength="6"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />

                {!!error && (
                  <p className="py-2 text-danger">
                    {error}
                    <a style={{ color: "#635afe" }} href="https://app.swiftreply.net/login">
                      {" "}
                      ( https://app.swiftreply.net/login )
                    </a>
                  </p>
                )}
                <button className="btn btn-form w-100 mt-3" disabled={loading || !info}>
                  SIGN UP
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppsumoRegisterForm;
