import React, { useState, useContext, useEffect } from "react";

import Swal from "sweetalert2";
import { AuthContext } from "../Contexts/AuthContext";

import { useNavigate } from "react-router-dom";

export default function TeamForm() {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const context = useContext(AuthContext);

  const { token, isLoggedIn, serverAddress, getAllTemplates, getAllTeams } =
    context;

  useEffect(() => {
    if (isLoggedIn === false) {
      //redirect (url guard)
      navigate("/login");
    }
    return () => {};
  }, []);

  const exitTeamCreation = () => {
    navigate("/menu");
  };

  const addTeam = () => {
    if (name == "") {
      Swal.fire({
        title: "Sorry!",
        text: "Workspace name is mandatory!",
        icon: "error",
        confirmButtonText: "Ok, Thanks!",
      });
      return;
    }

    let requestData = {
      name: name,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(requestData),
    };

    fetch(serverAddress + "/addTeam", requestOptions)
      .then((responseData) => {
        if (responseData.status == 401) {
          Swal.fire({
            title: "Sorry!",
            text: "You exceeded maximum number of teams.",
            icon: "error",
            confirmButtonText: "Ok, Thanks!",
          }).then(() => {
            exitTeamCreation();
          });
        } else {
          getAllTeams();
          exitTeamCreation();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <div
        id="FastEmail__popup-template"
        className="FastEmail__popup-background FastEmail__popup-background-center"
      >
        <div className="container">
          <form className="FastEmail__popup col-lg-7 mx-auto" action="#!">
            <div className="FastEmail__popup__header">
              <h1 className="FastEmail__popup__title m-0 flex-grow-1">
                New Workspace
              </h1>
              <button
                id="FastEmail__popup-template-btn"
                className="Close-btn"
                onClick={exitTeamCreation}
              >
                <svg
                  className="bi bi-x"
                  width="24px"
                  height="24px"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.854 4.146a.5.5 0 010 .708l-7 7a.5.5 0 01-.708-.708l7-7a.5.5 0 01.708 0z"
                    clip-rule="evenodd"
                  ></path>
                  <path
                    fill-rule="evenodd"
                    d="M4.146 4.146a.5.5 0 000 .708l7 7a.5.5 0 00.708-.708l-7-7a.5.5 0 00-.708 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            <hr className="mt-3 mb-0" style={{ color: "#88909b" }} />
            <div className="row d-flex flex-direction-row justify-content-center">
              <div className="col-lg-12 mt-4">
                <p className="FastEmail__popup__p">Workspace Name</p>
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="FastEmail__popup__input"
                  type="text"
                  placeholder="Name"
                  maxlength="20"
                />
              </div>
            </div>

            <a href="#" className="btn btn-secondary mt-4" onClick={addTeam}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="svgMargin bi bi-save"
                viewBox="0 0 16 16"
              >
                <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z" />
              </svg>
              Save Workspace
            </a>
          </form>
        </div>
      </div>
    </div>
  );
}
