import React, { useState, useEffect, useCallback } from "react";
import Swal from "sweetalert2";
import "./loginForm.css";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

const InvitationAcceptance = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [team, setTeam] = useState("");

  const validateToken = useCallback(async () => {
    try {
      // no token? go home
      const token = params?.token;
      if (!token || token.length < 100) {
        navigate("/");
      }
      // get invitation
      const res = await fetch(process.env.REACT_APP_API + "/invitation/" + token, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (res.status !== 200) {
        const msg = await res.text();
        throw new Error(msg);
      }
      const team = await res.text();
      setTeam(team);
    } catch (error) {
      await Swal.fire({
        title: "Error",
        icon: "error",
        text: error.message || "Something's wrong...",
      });
      navigate("/");
    }
  }, [params, navigate]);

  useEffect(() => {
    validateToken();
  }, [validateToken]);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!username.trim() || !password.trim()) {
      return await Swal.fire({
        title: "Error",
        icon: "error",
        text: "Please fill in username/password",
      });
    }
    try {
      setLoading(true);
      const res = await fetch(process.env.REACT_APP_API + "/invitation", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: params.token,
          username,
          password,
        }),
      });

      if (res.status !== 200) {
        const msg = await res.text();
        throw new Error(msg);
      }

      await Swal.fire({
        title: "Welcome Aboard!",
        icon: "success",
        html: `You've join team <strong>${team}</strong> successfully! <br /> Login and start sending amazing messages easily!`,
        confirmButtonText: "Login",
      });
      navigate("/login");
    } catch (error) {
      await Swal.fire({
        title: "Error",
        icon: "error",
        text: error.message || "Something's wrong...",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="space-between-blocks">
        <div className="container col-xxl-10 px-5">
          {/* <!-- HEADER --> */}
          <div className="FastEmail__logo-container mb-4">
            <a href="/">
              <img
                className="FastEmail__logo"
                src={"/images/SwiftReply.svg"}
                alt="FastEmail logo"
              />
            </a>
          </div>

          {/* <!-- Invitation FORM --> */}
          <div id="" className="FastEmail__Login row px-2 justify-content-center">
            {!!team ? (
              <div className="col-lg-5 mt-1 mt-lg-0">
                <form
                  className="FastEmail-auth-form"
                  style={{ textAlign: "center" }}
                  onSubmit={onSubmit}
                >
                  <h4 className="">Welcome 👋</h4>
                  <p className="mb-1">
                    You've been invited to join team: <strong>{team}</strong>
                  </p>
                  <p className="mb-3">Setup your username/password to join the team</p>
                  <input
                    id="userName"
                    className="FastEmail-auth-form__input"
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                  />
                  <input
                    id="password"
                    className="FastEmail-auth-form__input"
                    type="password"
                    minLength="6"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <button disabled={loading} type="submit" className="btn btn-form w-100 mt-3">
                    Join The Team
                  </button>
                </form>
              </div>
            ) : (
              <Spinner className="mt-5" animation="grow" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvitationAcceptance;
