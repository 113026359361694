import React, { useEffect, useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Contexts/AuthContext";
import Swal from "sweetalert2";

export default function GeneralSettings() {
  const navigate = useNavigate();
  const context = useContext(AuthContext);

  const { userSettings, setUserSettings, token, isLoggedIn, serverAddress, getAllTemplates } =
    context;

  const insertingValueRef = useRef();
  const quickSelectionRef = useRef();
  const menuSelectionRef = useRef();
  const enableExtensionRef = useRef();

  const [insertingValue, setInsertingValue] = useState({});
  const [settingsSelection, setSettingsSelection] = useState({});
  const [settingsMenu, setSettingsMenu] = useState({});
  const [extensionEnableMenu, setExtensionEnableMenu] = useState({});

  useEffect(() => {
    if (isLoggedIn === false) {
      //redirect (url guard)
      navigate("/login");
    }
    return () => {};
  }, []);

  useEffect(() => {

    insertingValueRef.current = userSettings.settingsIsAutoInsert;

    quickSelectionRef.current.value =
      userSettings.settingsQuickSelection && userSettings.settingsQuickSelection.key;

    menuSelectionRef.current.value =
      userSettings.settingsOpenMenu && userSettings.settingsOpenMenu.key;

    enableExtensionRef.current.value =
      userSettings.settingsEnableExtension && userSettings.settingsEnableExtension.key;

    if (userSettings.settingsOpenMenu) {
      setSettingsMenu(userSettings.settingsOpenMenu);
      setSettingsSelection(userSettings.settingsQuickSelection);
    }

  }, [userSettings]);

  const backToMenu = () => {
    navigate("/menu");
  };

  const submitChanges = async () => {
    if (Object.keys(settingsSelection).length === 0 || Object.keys(settingsMenu).length === 0) {
      return;
    }
    let newSettings = {
      settingsOpenMenu: settingsMenu,
      settingsQuickSelection: settingsSelection,
      settingsEnableExtension: extensionEnableMenu,
      settingsIsAutoInsert: insertingValueRef.current,
    };

    //sending them to the server....
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("fastEmailToken"),
      },
      body: JSON.stringify(newSettings),
    };
    const response = await fetch(serverAddress + "/updateSettings", requestOptions);

if (response.status != 202) {
    Swal.fire({
        title: "Sorry!",
        text: "Something went wrong.",
        icon: "error",
        confirmButtonText: "Try Again!",
    });
} else {
    Swal.fire({
        title: "Settings Updated!",
        text: "All settings are updated successfully.",
        icon: "success",
        confirmButtonText: "Ok, Thanks!",
    }).then(() => {
        setUserSettings(newSettings);
        backToMenu();
    });
}
};

  const getInputForQuickInsertion = (event) => {
    event.preventDefault();

    if (event.key === " ") event.key = "Space";
    let keyComb = "";

    if (event.altKey) {
      keyComb += "ALT+";
      event.stopPropagation();
    }
    if (event.shiftKey) {
      keyComb += "SHIFT+";
    }
    if (event.metaKey) {
      keyComb += "META+";
    }

    if (event.ctrlKey) {
      keyComb += "CTRL+";
    }

    keyComb += event.key;

    quickSelectionRef.current.value = keyComb;

    setSettingsSelection({ key: keyComb, keyCode: event.keyCode });
  };

  const getInputForMenuInsertion = (event) => {
    event.preventDefault();
    if (event.key === " ") event.key = "Space";
    let keyComb = "";

    if (event.altKey) {
      keyComb += "ALT+";
      event.stopPropagation();
    }
    if (event.shiftKey) {
      keyComb += "SHIFT+";
    }
    if (event.metaKey) {
      keyComb += "META+";
    }

    if (event.ctrlKey) {
      keyComb += "CTRL+";
    }

    keyComb += event.key;
    menuSelectionRef.current.value = keyComb;

    setSettingsMenu({ key: keyComb, keyCode: event.keyCode });
  };

  const getInputForEnableExtension = (event) => {
    event.preventDefault();
    if (event.key === " ") event.key = "Space";
    let keyComb = "";

    if (event.altKey) {
      keyComb += "ALT+";
      event.stopPropagation();
    }
    if (event.shiftKey) {
      keyComb += "SHIFT+";
    }
    if (event.metaKey) {
      keyComb += "META+";
    }

    if (event.ctrlKey) {
      keyComb += "CTRL+";
    }

    keyComb += event.key;
    enableExtensionRef.current.value = keyComb;

    setExtensionEnableMenu({ key: keyComb, keyCode: event.keyCode });
  };

  const getInitialState = () => {
    let dropdownValue = " ";
    const typeValue = parseInt(userSettings.settingsIsAutoInsert);
    if (typeValue === 0) {
      dropdownValue = "typed";
    } else {
      dropdownValue = "clicked";
    }
    return dropdownValue;
  };
  const [dropdownValue, setValue] = useState(getInitialState);
  const handleChange = (e) => {
    setValue(e.target.value);
    if (e.target.value === "typed") {
      insertingValueRef.current = 0;
    } else {
      insertingValueRef.current = 1;
    }
  };

  
  return (
    <div>
      <div
        id="FastEmail__popup-settings"
        className="FastEmail__popup-background FastEmail__popup-background-center"
      >
        <div className="container">
          <form className="FastEmail__popup col-lg-7 mx-auto" action="#!">
            <div className="FastEmail__popup__header">
              <h1 className="FastEmail__popup__title m-0 flex-grow-1 flex-lg-grow-0">General Settings</h1>
              <button id="FastEmail__popup-settings-btn" className="Close-btn" onClick={backToMenu}>
                <svg
                  className="bi bi-x"
                  width="24px"
                  height="24px"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.854 4.146a.5.5 0 010 .708l-7 7a.5.5 0 01-.708-.708l7-7a.5.5 0 01.708 0z"
                    clipRule="evenodd"
                  ></path>
                  <path
                    fillRule="evenodd"
                    d="M4.146 4.146a.5.5 0 000 .708l7 7a.5.5 0 00.708-.708l-7-7a.5.5 0 00-.708 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            <hr className="mt-3 mb-0" style={{ color: "#88909b" }} />
            <div class="FastEmail__popup__p mt-3"
            style={{backgroundColor: "#00ff091a", color: "white", border: "1px solid #4df160", padding: "10px", borderRadius: "5px"}}>
            <li>Combine up to 2-3 hotkeys combinations.</li>
            <li>Make sure other extensions don't use the same hotkeys.</li>
            </div>
            <hr className="mt-4 mb-0" style={{ color: "#88909b" }} />
            <p className="FastEmail__popup__p mt-4">Expand Shortcut Mode</p>
            <div>
            <select class="form-select" value={dropdownValue} onChange={handleChange}>
            <option value="typed">Immediately When Typed</option>
            <option value="clicked">After Clicking A Hotkey (At Delimiter)</option>
            </select>
            </div>
            <div style={
                insertingValueRef.current <= 0
                  ? {
                      display: "none"
                    }
                  : {
                      display: "block"
                    }
              }>
            <p className="FastEmail__popup__p-description mt-4">Write shortcut then insert template using a hotkey (Delimiter).</p>
            <input
              ref={quickSelectionRef}
              className="FastEmail__popup__input"
              type="text"
              placeholder="Press any keyboard key."
              onKeyDown={getInputForQuickInsertion}
              readOnly
            />
            </div>
            <hr className="mt-4 mb-0" style={{ color: "#88909b" }} />
            <p className="FastEmail__popup__p-description mt-4">Open a searchable templates directory using a hotkey.</p>
            <input
              ref={menuSelectionRef}
              className="FastEmail__popup__input"
              type="text"
              placeholder="Press any keyboard key."
              onKeyDown={getInputForMenuInsertion}
              readOnly
            />
            <p className="FastEmail__popup__p-description mt-4">Enable/Disable extension</p>
            <input
              ref={enableExtensionRef}
              className="FastEmail__popup__input"
              type="text"
              placeholder="Press any keyboard key."
              onKeyDown={getInputForEnableExtension}
              readOnly
            />

            <a href="#" className="btn btn-secondary mt-4" onClick={submitChanges}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="svgMargin bi bi-save"
                viewBox="0 0 16 16"
              >
                <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z" />
              </svg>
              Save Changes
            </a>
          </form>
        </div>
      </div>
    </div>
  );
}
