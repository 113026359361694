import axios from "axios";
export class TinyImgSrcParser {
  constructor(html = "", token = "", SweetAlert) {
    this.html = html;
    this.token = token;
    // This div has TinyMCE html dom elements
    this.elements = [];
    // SweetAlert Obj to control alerts
    this.SweetAlert = SweetAlert;
  }
  async upload() {
    try {
      // 1. parse
      const form = await this.parse();
      // 2. upload
      if (form && form.has("images")) {
        const res = await axios.post(`${process.env.REACT_APP_API}/upload`, form, {
          onUploadProgress: (p) => this.updateProgress(p, this.SweetAlert),
          headers: {
            Authorization: "Bearer " + this.token,
          },
        });

        // 3. Replaced indexed src img elements with uploaded paths
        const images = this.elements.querySelectorAll("img");
        let setIndex = 0;
        for (const img of images) {
          // Replace only if it's an index/number
          // To prevent not edited src's while editing a template
          if (+img.getAttribute("src") >= 0) {
            img.src = `${process.env.REACT_APP_API}/${res.data[setIndex]}`;
            setIndex++;
          }
        }
        this.closeAlert();
        return this.elements.innerHTML;
      }
      this.closeAlert();
      return this.html;
    } catch (error) {
      console.log(error);
      // TODO: handle
    }
  }
  async parse() {
    // Loop through the img elements in the html then get the base64 src
    // The idea is to mark the img src with index, then replace it with the uploaded path
    let index = 0;

    // create element to get img src(es)
    this.elements = document.createElement("div");
    this.elements.innerHTML = this.html;
    const images = this.elements.querySelectorAll("img");
    if (!images.length) return;

    this.showAlert();

    // convert img elements to File obj
    const form = new FormData();
    for (const imgEl of images) {
      try {
        // Omit if not base64
        if (imgEl.src.startsWith("data:image/")) {
          const imgFile = await this.convertImageElementToFile(imgEl);
          if (imgFile) {
            // Mark this src with the index to replace it later
            imgEl.src = index;
            form.append("images", imgFile);
            index++;
          }
        }
      } catch (error) {
        alert("Error parsing images, something wrong with the <img src='?'> ");
      }
    }

    return form;
  }
  /**
   *
   * @param { HTMLImageElement } imgEl
   * Convert the image base64 src
   * into uploadable File object
   * @returns File | undefined
   */
  async convertImageElementToFile(imgEl = {}) {
    const name = imgEl.alt || Date.now();
    if (!imgEl.src) return;
    const res = await fetch(imgEl.src);
    const blob = await res.blob();
    const ext = blob.type.split("/")[1];
    const nameWithExt = name + "." + ext;
    return new File([blob], nameWithExt, { type: blob.type });
  }

  /**
   *
   * @param {Axios progress Obj} progress
   *
   * Update progress of the upload
   *
   * This probably should be removed
   * Most likely you have your own progress bar UI
   * You can use this number to update your UI
   *
   * @returns void
   */
  updateProgress(progress, SWA) {
    const { total, loaded } = progress;
    const totalSizeInMB = total / 1000000;
    const loadedSizeInMB = loaded / 1000000;
    const uploadPercentage = (loadedSizeInMB / totalSizeInMB) * 100;

    // Update UI
    SWA.update({
      html: this.getAlertProgressText(uploadPercentage.toFixed(0)),
    });
  }

  getAlertProgressText(progress = 0) {
    return `Images are getting uploaded <br/> <h4 class="mt-2">${progress}%</h4> it might take a little bit for large images`;
  }
  /**
   * Show uploading progress alert
   */
  showAlert() {
    this.SweetAlert.fire({
      title: "Please Hold!",
      html: this.getAlertProgressText(),
      icon: "info",
      allowEscapeKey: false,
      showConfirmButton: false,
      allowOutsideClick: false,
    });
  }
  closeAlert() {
    if (this.SweetAlert.isVisible()) {
      this.SweetAlert.close();
    }
  }
}
