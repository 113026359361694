import React from "react";

const SUMO_DESCS = [
  "Embed unlimited videos",
  "Insert unlimited images and tables",
  "Fully customizable canned responses",
  "Personal workspace",
  "Create multiple workspaces and share them with the entire team",
  "Add unlimited team members to workspaces",
  "Insert canned response with one Hotkey",
  "Chrome extension",
  "iOS and Android app",
  "Compatible with eCommerce and social media sites",
  "Compatible with Gmail, Outlook, Slack, and WhatsApp",
  "Compatible with HubSpot",
  "Ready templates library",
  "Allow users to use 3 keyboard combinations",
  "Use arrows (up/down) to move between the templates in the extension",
  "Toggle button to enable/disable SwiftReply",
];
const PlanDescription = ({ isAppSumo }) => {
  if (isAppSumo) {
    return (
      <>
        <p className="FastEmail__popup__p mt-3">Features included in all plans</p>
        <ul className="list-unstyled mb-0">
          {SUMO_DESCS.map((d, i) => (
            <ListItem key={i} text={d} />
          ))}
        </ul>
      </>
    );
  }

  return (
    <>
      <p className="FastEmail__popup__p-description" style={{ marginTop: "15px" }}>
        {'If you upgrade your Membership to a higher tier, you will be charged a "prorated" rate. You will get a discount ' +
          "for the remaining part of the subscription, which will be subtracted from your new Membership rate. This discount is calculated " +
          "as of the end of the current day."}
      </p>
      <p className="FastEmail__popup__p-description" style={{ marginTop: "15px" }}>
        {`If you downgrade your Membership (move to a lower tier) your current plan won't change
              immediately. We will wait until the end of your billing cycle to downgrade.`}
      </p>
      <p className="FastEmail__popup__p-description" style={{ marginTop: "15px" }}>
        {`You will get a receipt of you change in your email inbox.`}
      </p>
    </>
  );
};

const ListItem = ({ text }) => {
  return (
    <li>
      <svg
        style={{ marginRight: 10 }}
        width="25"
        viewBox="0 0 16 16"
        fill="#33c58d"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"
        ></path>
      </svg>
      <span className="FastEmail__popup__p-description">{text}</span>
    </li>
  );
};
export default PlanDescription;
