import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import PlanDescription from "../Components/PlanDescription";
import { AuthContext } from "./AuthContext";

export default function BillingMenu() {
  const context = useContext(AuthContext);

  const {
    serverAddress,
    currentTeams,
    currentTemplates,
    maxTeams,
    maxTemplates,
    subscriptionType,
    timeToDisplay,
    isActive,
    subscription_id,
  } = context;

  const navigate = useNavigate();

  const backToMenu = () => {
    navigate("/menu");
  };

  useEffect(() => {
    if (subscriptionType == "Regular") {
      navigate("/billingMenu");
    }
  }, []);

  useEffect(() => {
    console.log(currentTeams);
  }, [maxTeams]);

  const isAppSumo = subscriptionType.startsWith("AppSumo");

  return (
    <div>
      <div
        id="FastEmail__popup-settings"
        className="FastEmail__popup-background FastEmail__popup-background-center"
      >
        <div className="container">
          <form className="FastEmail__popup col-lg-6 mx-auto" action="#!">
            <div className="FastEmail__popup__header">
              <h1 className="FastEmail__popup__title m-0 flex-grow-1">Billing</h1>
              <button id="FastEmail__popup-settings-btn" className="Close-btn" onClick={backToMenu}>
                <svg
                  className="bi bi-x"
                  width="24px"
                  height="24px"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.854 4.146a.5.5 0 010 .708l-7 7a.5.5 0 01-.708-.708l7-7a.5.5 0 01.708 0z"
                    clipRule="evenodd"
                  ></path>
                  <path
                    fillRule="evenodd"
                    d="M4.146 4.146a.5.5 0 000 .708l7 7a.5.5 0 00.708-.708l-7-7a.5.5 0 00-.708 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            <hr className="mt-3 mb-0" style={{ color: "#88909b" }} />
            {isAppSumo && (
              <img className="FastEmail__logo mt-3" src="/images/appsumo.svg" alt="AppSumo logo" />
            )}
            <p
              className="FastEmail__popup__p mt-3"
              style={
                currentTeams <= maxTeams && currentTemplates <= maxTemplates
                  ? {
                      padding: "10px",
                      borderRadius: "3px",
                      backgroundColor: "#00ff091a",
                      color: "white",
                      border: "1px solid #4df160"
                    }
                  : {
                      padding: "10px",
                      borderRadius: "3px",
                      backgroundColor: "#ff00071a",
                      color: "white",
                      border: "1px solid #f14d4d"
                    }
              }
            >

              <b>{subscriptionType + " Plan: "}</b>
              
              {"Templates left "}
              <b>
              {parseInt(maxTemplates) > 99999
                ? currentTemplates + "/Unlimited"
                : currentTemplates + "/" + maxTemplates
              }
              </b>

              {" & Shared Workspace left "}
              <b>
              {parseInt(maxTeams) > 99999
                ? parseInt(currentTeams) + "/Unlimited"
                : parseInt(currentTeams) + '/'+ parseInt(maxTeams)
              }
              </b>

            </p>
            <p
              className="FastEmail__popup__p-description"
              style={{ fontWeight: "400", fontSize: "16px", marginTop: "5px" }}
            >
              {isAppSumo
                ? "Your subscription will never expire"
                : "Your subscription will end on " + timeToDisplay.toLocaleDateString()}
            </p>

            <a
              href="#"
              className="btn btn-secondary mt-4"
              onClick={() => {
                const url = isAppSumo
                  ? `https://appsumo.com/account/redemption/${subscription_id}#change-plan`
                  : `https://app.gumroad.com/subscriptions/${subscription_id}/manage`;
                window.open(url);
              }}
            >
              <svg
                className="svgMargin bi bi-save svg-inline--fa fa-credit-card fa-w-18"
                aria-hidden="true"
                focusable="false"
                data-prefix="far"
                data-icon="credit-card"
                width="18px"
                height="18px"
                style={{ transform: "translate(-10px, -1px)" }}
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
              >
                <path
                  fill="currentColor"
                  d="M527.9 32H48.1C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48.1 48h479.8c26.6 0 48.1-21.5 48.1-48V80c0-26.5-21.5-48-48.1-48zM54.1 80h467.8c3.3 0 6 2.7 6 6v42H48.1V86c0-3.3 2.7-6 6-6zm467.8 352H54.1c-3.3 0-6-2.7-6-6V256h479.8v170c0 3.3-2.7 6-6 6zM192 332v40c0 6.6-5.4 12-12 12h-72c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h72c6.6 0 12 5.4 12 12zm192 0v40c0 6.6-5.4 12-12 12H236c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h136c6.6 0 12 5.4 12 12z"
                ></path>
              </svg>
              {isAppSumo ? "Change Plan" : "Manage Payment"}
            </a>
            <hr className="my-4 mb-0" style={{ color: "rgb(136, 144, 155)" }}></hr>
            <PlanDescription isAppSumo={isAppSumo} />
          </form>
        </div>
      </div>
    </div>
  );
}
