import React, { useContext, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { AuthContext } from "../Contexts/AuthContext";

export default function RenameTeam() {
  const navigate = useNavigate();

  const backToMenu = () => {
    navigate("/menu");
  };
  const [teamName, setTeamName] = useState("");

  const { id } = useParams();
  const context = useContext(AuthContext);
  const {
    teams,
    token,
    serverAddress,
    isLoggedIn,
    getAllTemplates,
    getAllTeams,
  } = context;

  useEffect(() => {
    if (isLoggedIn === false) {
      //redirect (url guard)
      navigate("/login");
    }
    return () => {};
  }, []);

  useEffect(() => {
    let thisTeam = teams.filter((t) => t.id == id);
    if (thisTeam.length == 0) {
      return;
    }

    setTeamName(thisTeam[0].name);
  }, [teams]);

  const requestNameChange = async () => {
    if (teamName == "") {
      Swal.fire({
        title: "Sorry!",
        text: "Workspace name cannot be empty!",
        icon: "error",
        confirmButtonText: "Ok, Thanks!",
      });
      return;
    }

    if (teams.filter((t) => t.name == teamName).length > 0) {
      Swal.fire({
        title: "Sorry!",
        text: "Workspace name duplicated!",
        icon: "error",
        confirmButtonText: "Ok, Thanks!",
      });
      return;
    }

    const requestData = {
      teamName: teamName,
      teamId: id,
    };

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("fastEmailToken"),
      },
      body: JSON.stringify(requestData),
    };

    const response = await fetch(serverAddress + "/renameTeam", requestOptions);
    console.log(response.status);

    if (response.status == 200) {
      Swal.fire({
        title: "Workspace renamed!",
        text: "Workspace name has been updated!",
        icon: "success",
        confirmButtonText: "Ok, Thanks!",
      }).then(() => {
        getAllTeams();
        backToMenu();
      });
    } else {
      Swal.fire({
        title: "Workspace renamed!",
        text: "Workspace name has been updated!",
        icon: "success",
        confirmButtonText: "Ok, Thanks!",
      }).then(() => {
        getAllTeams();
        backToMenu();
      });
    }

    /*
    if (response.status == 200) {
      Swal.fire({
        title: "Workspace renamed!",
        text: "Workspace name has been updated!",
        icon: "success",
        confirmButtonText: "Ok, Thanks!",
      }).then(() => {
        getAllTeams();
        backToMenu();
      });
    } else {
      Swal.fire({
        title: "Sorry!",
        text: "Something went wrong!",
        icon: "error",
        confirmButtonText: "Ok, Thanks!",
      });
    }
    */
    // const responseData = await response.json();
  };

  return (
    <div>
      <div
        id="FastEmail__popup-settings"
        className="FastEmail__popup-background"
      >
        <div className="container">
          <form className="FastEmail__popup col-lg-7 mx-auto" action="#!">
            <div className="FastEmail__popup__header">
              <h1 className="FastEmail__popup__title m-0 flex-grow-1">
                Rename Workspace
              </h1>
              <button
                id="FastEmail__popup-settings-btn"
                className="Close-btn"
                onClick={backToMenu}
              >
                <svg
                  className="bi bi-x"
                  width="24px"
                  height="24px"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.854 4.146a.5.5 0 010 .708l-7 7a.5.5 0 01-.708-.708l7-7a.5.5 0 01.708 0z"
                    clipRule="evenodd"
                  ></path>
                  <path
                    fillRule="evenodd"
                    d="M4.146 4.146a.5.5 0 000 .708l7 7a.5.5 0 00.708-.708l-7-7a.5.5 0 00-.708 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            <hr className="mt-3 mb-0" style={{ color: "#88909b" }} />
            <p className="FastEmail__popup__p mt-3">Workspace name</p>

            <input
              className="FastEmail__popup__input"
              type="email"
              placeholder="Email"
              value={teamName}
              onChange={(e) => setTeamName(e.currentTarget.value)}
            />

            <a
              href="#"
              className="btn btn-secondary mt-4"
              onClick={requestNameChange}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="svgMargin bi bi-save"
                viewBox="0 0 16 16"
              >
                <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z" />
              </svg>
              Save changes
            </a>
          </form>
        </div>
      </div>
    </div>
  );
}
