import "./App.css";
import AuthContextProvider from "./Contexts/AuthContext";
import MainRouter from "./Components/MainRouter";
function App() {
  return (
    <div className="App">
      <AuthContextProvider>
        <MainRouter></MainRouter>
      </AuthContextProvider>
    </div>
  );
}

export default App;
