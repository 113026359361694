import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Contexts/AuthContext";
import Swal from "sweetalert2";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const [isWorkSpaceMenuOpen, setWorkSpaceMenuOpen] = useState(false);
  const [isWorkSpaceMenuOpenMobile, setWorkSpaceMenuOpenMobile] = useState(false);

  const authService = useContext(AuthContext);

  const [teams, setTeams] = useState([]);

  const navigate = useNavigate();

  const AddNewTemplate = () => {
    navigate("/createTemplate");
  };
  const goToSettings = () => {
    navigate("/generalSettings");
  };
  const createTeam = () => {
    navigate("/createTeam");
  };

  const deleteTeam = (teamId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "All templates in this workspace will be deleted! You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let requestData = {
          teamId: teamId,
        };
        const requestOptions = {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + authService.token,
          },
          body: JSON.stringify(requestData),
        };

        fetch(authService.serverAddress + "/deleteTeam", requestOptions)
          .then((responseData) => {
            if (responseData.status != 200) {
              Swal.fire({
                title: "Sorry!",
                text: "Something went wrong.",
                icon: "error",
                confirmButtonText: "Try Again!",
              });
            } else {
              authService.getAllTeams();
              Swal.fire({
                title: "Workspace Deleted!",
                text: "The workspace with templates has been deleted.",
                icon: "success",
                confirmButtonText: "Ok, Thanks!",
              }).then(() => {
                window.location.reload(false);
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  useEffect(() => {
    setTeams(authService.teams);

    //console.log("authService: " + JSON.stringify(authService));

    return () => {};
  }, [authService.teams]);

  const Knowledgebase = () => {
    const win = window.open("https://help.swiftreply.net", "_blank");
    if (win != null) {
      win.focus();
    }
  };

  const InstallExtension = () => {
    const win = window.open(
      "https://chrome.google.com/webstore/detail/swiftreply-canned-respons/paieegkfieiihagabeicpnghjdhpeidb",
      "_blank"
    );
    if (win != null) {
      win.focus();
    }
  };

  const InstallAndroid = () => {
    const win = window.open(
      "https://play.google.com/store/apps/details?id=app.swiftreply.com",
      "_blank"
    );
    if (win != null) {
      win.focus();
    }
  };

  const InstalliOS = () => {
    const win = window.open("https://apps.apple.com/us/app/swiftreply/id1629043921", "_blank");
    if (win != null) {
      win.focus();
    }
  };

  const logout = () => {
    localStorage.removeItem("fastEmailToken");
    authService.setToken("");
    authService.setLoggedIn(false);
    navigate("/login");
    const event = new Event("fastEmailLogout");
    document.dispatchEvent(event);
    window.location.reload(false);
  };

  const deleteAccount = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "All data will be deleted! You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete my data!",
    }).then((result) => {
      if (result.isConfirmed) {
        const requestOptions = {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + authService.token,
          },
        };
        fetch(authService.serverAddress + "/deleteAccount", requestOptions)
          .then((response) => response.json())
          .then((data) => {
            Swal.fire("DELETED", "Your data has been deleted successfully.", "success");
            navigate("/login");
          })
          .catch((err) => {
            Swal.fire("ERROR", "Something went wrong while deleting your data, please contact support hello@app.swiftreply.net", "error");
          });
      }
    });
  };


  return (
    <div>
      <header className="hero container-fluid position-relative">
        <div className="hero__content container">
          <div className="fixed-nav-container">
            <nav className="hero-nav position-relative container mx-auto px-0">
              <ul className="nav w-100 list-unstyled align-items-center p-0">
                <li className="hero-nav__item flex-grow-1 flex-lg-grow-0">
                  <a href="#" onClick={() => navigate("/menu")}>
                    <img
                      className="hero-nav__logo"
                      src="/images/SwiftReply-Logo.svg"
                      change-src-onscroll="/images/SwiftReply-Logo.svg"
                      alt="SwiftReply logo"
                    />
                  </a>
                  <span className="mx-2"></span>
                </li>
                <li
                  id="hero-menu"
                  className={
                    isMobileMenuOpen === true
                      ? "flex-grow-1 hero__nav-list hero__nav-list--mobile-menu ft-menu text-light ft-menu--js-show"
                      : "flex-grow-1 hero__nav-list hero__nav-list--mobile-menu ft-menu text-light"
                  }
                >
                  <ul
                    className="
                  hero__menu-content
                  nav
                  flex-column flex-lg-row
                  ft-menu__slider
                  animated
                  list-unstyled
                  p-2 p-lg-0
                  "
                  >
                    <li className="flex-grow-1">
                      <ul
                        className="
                        nav nav--lg-side
                        list-unstyled
                        align-items-center
                        p-0
                        "
                      >
                        <li
                          onClick={() => {
                            if (window.innerWidth < 500) {
                              setIsMenuOpen(!isMenuOpen);
                            }
                          }}
                          onMouseEnter={() => setIsMenuOpen(true)}
                          onMouseLeave={() => setIsMenuOpen(false)}
                          className={
                            isMenuOpen === true
                              ? "hero-nav__item hero-nav__item--with-dropdown hero-nav__item--show-dropdown"
                              : "hero-nav__item hero-nav__item--with-dropdown"
                          }
                        >
                          <span className="hero-nav__link" tabIndex="1" role="button">
                            <span className="flex-grow-1 me-2">Account</span>
                            <svg
                              className="hero-nav__item-chevron bi bi-chevron-down"
                              width=".8em"
                              height=".8em"
                              viewBox="0 0 16 16"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                              ></path>
                            </svg>
                          </span>
                          <div
                            className="
                              hero-nav__dropdown
                              dropdown dropdown--of-1-columns
                              "
                            style={
                              (isMenuOpen === true || isMobileMenuOpen === true) &&
                              window.innerWidth < 900
                                ? {
                                    transform: "inherit",
                                  }
                                : {
                                    transform: "translateX(calc(-50% + 44.3672px)",
                                  }
                            }
                          >
                            <div className="row flex-wrap">
                              <div className="col-lg-12">
                                <a
                                  id="GeneralSettings-btn"
                                  href="#"
                                  className="dropdown__link"
                                  onClick={goToSettings}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="dropdown__icon bi bi-gear"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
                                    <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
                                  </svg>
                                  <span className="mx-2"></span>
                                  <div className="dropdown__item">
                                    <span className="dropdown__item-title">General Settings</span>
                                  </div>
                                </a>

                                <a
                                  href="#"
                                  className="dropdown__link"
                                  onClick={() => navigate("/billingOptions")}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="dropdown__icon bi bi-credit-card"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z" />
                                    <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z" />
                                  </svg>
                                  <span className="mx-2"></span>
                                  <div className="dropdown__item">
                                    <span className="dropdown__item-title">Billing</span>
                                  </div>
                                </a>

                                <a href="#" className="dropdown__link" onClick={Knowledgebase}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="dropdown__icon bi bi-question-circle"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                    <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
                                  </svg>
                                  <span className="mx-2"></span>
                                  <div className="dropdown__item">
                                    <span className="dropdown__item-title">Knowledge Base</span>
                                  </div>
                                </a>

                                <a href="#" className="dropdown__link" onClick={InstallExtension}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-browser-chrome"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M16 8a8.001 8.001 0 0 1-7.022 7.94l1.902-7.098a2.995 2.995 0 0 0 .05-1.492A2.977 2.977 0 0 0 10.237 6h5.511A8 8 0 0 1 16 8ZM0 8a8 8 0 0 0 7.927 8l1.426-5.321a2.978 2.978 0 0 1-.723.255 2.979 2.979 0 0 1-1.743-.147 2.986 2.986 0 0 1-1.043-.7L.633 4.876A7.975 7.975 0 0 0 0 8Zm5.004-.167L1.108 3.936A8.003 8.003 0 0 1 15.418 5H8.066a2.979 2.979 0 0 0-1.252.243 2.987 2.987 0 0 0-1.81 2.59ZM8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
                                    />
                                  </svg>

                                  <span className="mx-2"></span>
                                  <div className="dropdown__item">
                                    <span className="dropdown__item-title">Install Extension</span>
                                  </div>
                                </a>

                                <a href="#" className="dropdown__link" onClick={InstallAndroid}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-android2"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="m10.213 1.471.691-1.26c.046-.083.03-.147-.048-.192-.085-.038-.15-.019-.195.058l-.7 1.27A4.832 4.832 0 0 0 8.005.941c-.688 0-1.34.135-1.956.404l-.7-1.27C5.303 0 5.239-.018 5.154.02c-.078.046-.094.11-.049.193l.691 1.259a4.25 4.25 0 0 0-1.673 1.476A3.697 3.697 0 0 0 3.5 5.02h9c0-.75-.208-1.44-.623-2.072a4.266 4.266 0 0 0-1.664-1.476ZM6.22 3.303a.367.367 0 0 1-.267.11.35.35 0 0 1-.263-.11.366.366 0 0 1-.107-.264.37.37 0 0 1 .107-.265.351.351 0 0 1 .263-.11c.103 0 .193.037.267.11a.36.36 0 0 1 .112.265.36.36 0 0 1-.112.264Zm4.101 0a.351.351 0 0 1-.262.11.366.366 0 0 1-.268-.11.358.358 0 0 1-.112-.264c0-.103.037-.191.112-.265a.367.367 0 0 1 .268-.11c.104 0 .19.037.262.11a.367.367 0 0 1 .107.265c0 .102-.035.19-.107.264ZM3.5 11.77c0 .294.104.544.311.75.208.204.46.307.76.307h.758l.01 2.182c0 .276.097.51.292.703a.961.961 0 0 0 .7.288.973.973 0 0 0 .71-.288.95.95 0 0 0 .292-.703v-2.182h1.343v2.182c0 .276.097.51.292.703a.972.972 0 0 0 .71.288.973.973 0 0 0 .71-.288.95.95 0 0 0 .292-.703v-2.182h.76c.291 0 .54-.103.749-.308.207-.205.311-.455.311-.75V5.365h-9v6.404Zm10.495-6.587a.983.983 0 0 0-.702.278.91.91 0 0 0-.293.685v4.063c0 .271.098.501.293.69a.97.97 0 0 0 .702.284c.28 0 .517-.095.712-.284a.924.924 0 0 0 .293-.69V6.146a.91.91 0 0 0-.293-.685.995.995 0 0 0-.712-.278Zm-12.702.283a.985.985 0 0 1 .712-.283c.273 0 .507.094.702.283a.913.913 0 0 1 .293.68v4.063a.932.932 0 0 1-.288.69.97.97 0 0 1-.707.284.986.986 0 0 1-.712-.284.924.924 0 0 1-.293-.69V6.146c0-.264.098-.491.293-.68Z" />
                                  </svg>

                                  <span className="mx-2"></span>
                                  <div className="dropdown__item">
                                    <span className="dropdown__item-title">Install Android</span>
                                  </div>
                                </a>

                                <a href="#" className="dropdown__link" onClick={InstalliOS}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-apple"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43Zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282Z" />
                                    <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43Zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282Z" />
                                  </svg>

                                  <span className="mx-2"></span>
                                  <div className="dropdown__item">
                                    <span className="dropdown__item-title">Install iOS</span>
                                  </div>
                                </a>

                                <a href="#" className="dropdown__link" onClick={logout}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="dropdown__icon bi bi-box-arrow-right"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"
                                    />
                                    <path
                                      fillRule="evenodd"
                                      d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
                                    />
                                  </svg>
                                  <span className="mx-2"></span>
                                  <div className="dropdown__item">
                                    <span className="dropdown__item-title">Logout</span>
                                  </div>
                                </a>

                                <a href="#" className="dropdown__link_last" onClick={deleteAccount}>
                                  
                                  <svg xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="#fb526a"
                                  class="dropdown__icon bi bi bi-trash"
                                  viewBox="0 0 16 16">
                                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                  <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                  </svg>
                                  
                                  <span className="mx-2"></span>
                                  <div className="dropdown__item">
                                    <span className="dropdown__item-title">Delete Account</span>
                                  </div>
                                </a>

                              </div>
                            </div>
                          </div>
                        </li>

                        <li
                          onClick={() => {
                            if (window.innerWidth < 500) {
                              setWorkSpaceMenuOpen(!isWorkSpaceMenuOpen);
                            }
                          }}
                          onMouseEnter={() => setWorkSpaceMenuOpen(true)}
                          onMouseLeave={() => setWorkSpaceMenuOpen(false)}
                          className={
                            isWorkSpaceMenuOpen === true
                              ? "hero-nav__item hero-nav__item--with-dropdown hero-nav__item--show-dropdown"
                              : "hero-nav__item hero-nav__item--with-dropdown"
                          }
                        >
                          <span className="hero-nav__link" tabIndex="1" role="button">
                            <span className="flex-grow-1 me-2">Workspaces</span>
                            <svg
                              className="hero-nav__item-chevron bi bi-chevron-down"
                              width=".8em"
                              height=".8em"
                              viewBox="0 0 16 16"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                              ></path>
                            </svg>
                          </span>
                          <div
                            className="
                              hero-nav__dropdown dropdown_workspace
                              dropdown dropdown--of-1-columns
                              "
                            style={
                              (isWorkSpaceMenuOpen === true ||
                                isWorkSpaceMenuOpenMobile === true) &&
                              window.innerWidth < 900
                                ? {
                                    transform: "inherit",
                                  }
                                : {
                                    transform: "translateX(calc(-50% + 44.3672px)",
                                  }
                            }
                          >
                            <div className="row flex-wrap">
                                <a href="#" className="dropdown__link mb-0" onClick={createTeam}>
                                  <div className="dropdown__item">
                                    <span className="btn btn-tertiary">
                                      <svg
                                        className="svgMargin"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"></path>
                                      </svg>
                                      New Workspace
                                    </span>
                                  </div>
                                </a>

                                <span
                                  className="dropdown__link"
                                  style={{ borderTop: "1px solid gray", margin: "2rem 0" }}
                                  onClick={() => {}}
                                ></span>

                              <div className="col-lg-12 dropdown__workspaces-list">
                                {teams.map((team) => {
                                  return (
                                    <div style={{ display: "flex" }} key={team.id}>
                                      <a
                                        key={team.id}
                                        href="#"
                                        className="dropdown__link d-flex flex-grow-1"
                                        onClick={() => {
                                          authService.setselectedTeam(team.id);
                                        }}
                                      >
                                        {team.leaderId !=
                                        JSON.stringify(authService.teams[0].leaderId) ? (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="19"
                                            height="19"
                                            fill="currentColor"
                                            class="bi bi-people"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
                                          </svg>
                                        ) : (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            class="bi bi-dash"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
                                          </svg>
                                        )}
                                        <span className="mx-2"></span>
                                        <div className="dropdown__item">
                                          <span
                                            className="dropdown__item-title"
                                            style={
                                              authService.selectedTeam == team.id
                                                ? { color: "#635afe" }
                                                : {}
                                            }
                                          >
                                            {team.name}
                                          </span>
                                        </div>
                                      </a>
                                      {team.editable === true ? (
                                        <>
                                          <svg
                                            onClick={() => navigate("/renameTeam/" + team.id)}
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="#88909b"
                                            className=" svgMargin bi bi-pencil-square"
                                            viewBox="0 0 16 16"
                                            style={{
                                              margin: "0 0 0 10px",
                                              cursor: "pointer",
                                              minWidth: "16px"
                                            }}
                                          >
                                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                            <path
                                              fillRule="evenodd"
                                              d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                            />
                                          </svg>

                                          <svg
                                            onClick={() => navigate("/editTeam/" + team.id)}
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="17"
                                            height="17"
                                            fill="#88909b"
                                            className="svgMargin bi bi-person-plus"
                                            viewBox="0 0 16 16"
                                            style={{
                                              margin: "0 0 0 10px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                                            <path
                                              fillRule="evenodd"
                                              d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"
                                            />
                                          </svg>

                                          <svg
                                            onClick={() => deleteTeam(team.id)}
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="#db4848"
                                            className="svgMargin bi bi-trash"
                                            viewBox="0 0 16 16"
                                            style={{
                                              margin: "0 0 0 10px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                            <path
                                              fillRule="evenodd"
                                              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                            />
                                          </svg>
                                        </>
                                      ) : (
                                        <>
                                          {team.leaderId !=
                                          JSON.stringify(authService.teams[0].leaderId) ? (
                                            <>
                                              {team.add_templates_permission === 1 ? (
                                                <span class="FastEmail-card__permissions">Add</span>
                                              ) : (
                                                ""
                                              )}

                                              {team.edit_templates_permission === 1 ? (
                                                <span class="FastEmail-card__permissions ms-2">
                                                  Edit
                                                </span>
                                              ) : (
                                                ""
                                              )}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      )}
                                    </div>
                                  );
                                })}

                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="flex-grow-1">
                          <ul
                            className="
                              nav nav--lg-side
                              list-unstyled
                              align-items-center
                              p-0
                              "
                          >
                            <li className="hero-nav__item" onClick={() => AddNewTemplate()}>
                              <a id="NewTemplate-btn" href="#" className="btn btn-secondary">
                                <svg
                                  className="svgMargin"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"></path>
                                </svg>
                                New Template
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <button
                    onClick={() => setIsMobileMenuOpen(false)}
                    close-nav-menu=""
                    className="ft-menu__close-btn animated"
                  >
                    <svg
                      className="bi bi-x"
                      width="1em"
                      height="1em"
                      viewBox="0 0 16 16"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M11.854 4.146a.5.5 0 010 .708l-7 7a.5.5 0 01-.708-.708l7-7a.5.5 0 01.708 0z"
                        clipRule="evenodd"
                      ></path>
                      <path
                        fillRule="evenodd"
                        d="M4.146 4.146a.5.5 0 000 .708l7 7a.5.5 0 00.708-.708l-7-7a.5.5 0 00-.708 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </li>
                <li className="hero-nav__item d-lg-none d-flex flex-row-reverse">
                  <button
                    onClick={() => {
                      setIsMobileMenuOpen(true);
                    }}
                    open-nav-menu
                    className="text-center text-light px-2"
                  >
                    <i className="fas fa-bars"></i>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
