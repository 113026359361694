import React, { useState, useContext } from "react";

import Swal from "sweetalert2";

import "./loginForm.css";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Contexts/AuthContext";

const RegisterForm = () => {
  const { serverAddress } = useContext(AuthContext);

  const navigate = useNavigate();

  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const goToLogin = () => {
    navigate("/login");
  };

  const register = (e) => {
    e.preventDefault();
    // console.log(username, password, email);
    setIsPending(true);

    let requestData = { email: email, username: username, password: password };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    };

    fetch(serverAddress + "/register", requestOptions)
      .then((responseData) => {
        if (responseData.status == 403) {
          Swal.fire({
            title: "Oups!",
            text: "Email already registered!",
            icon: "error",
            confirmButtonText: "Alight!",
          });
          return;
        }
        setError(null);
        setIsPending(false);
        Swal.fire({
          title: "Great!",
          text: "User registered!",
          icon: "success",
          confirmButtonText: "Cool",
        });
        navigate("/login");
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: "Oups!",
          text: "Email already registered!",
          icon: "error",
          confirmButtonText: "Alight!",
        });

        setIsPending(false);
        setError(err.message);
      });
  };
  return (
    <div className="FastEmail_Background">
      <div className="space-between-blocks">
        <div className="container col-xxl-10 px-xxl-5">
          {/* <!-- HEADER --> */}
          <div className="FastEmail__logo-container">
            <a href="/">
              <img
                className="FastEmail__logo"
                src={"/images/SwiftReply.svg"}
                alt="SwiftReply logo"
              />
            </a>
          </div>
          {/* <!-- SIGN UP FORM --> */}
          <div id="Signup" className="FastEmail__Signup row px-2 justify-content-center">
            <div className="col-lg-5 mt-5 mt-lg-0">
              <form className="FastEmail-auth-form" action="#!" onSubmit={register}>
                <h1 className="FastEmail-auth-form__title">Create New Account</h1>
                <input
                  id="userName"
                  className="FastEmail-auth-form__input"
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
                <input
                  id="email"
                  className="FastEmail-auth-form__input"
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <input
                  id="password"
                  className="FastEmail-auth-form__input"
                  type="password"
                  minLength="6"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <button className="btn btn-form w-100 mt-3">SIGN UP</button>
                <p className="FastEmail-auth-form__p mt-4 mb-0">
                  Already a member?
                  <a id="Login-btn" className="FastEmail__link" href="#" onClick={goToLogin}>
                    {" "}
                    Sign In
                  </a>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterForm;
